import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AddressDetails,
  CustomerAccountItem,
} from '../models/customer-account-item';

@Injectable({
  providedIn: 'root',
})
export class CustomerAccountsService {
  customerAccountsUrl = `${environment.ApiBaseUrl}/customer-accounts`;
  constructor(private readonly http: HttpClient) {}

  getAccounts(): Observable<CustomerAccountItem[]> {
    return this.http.get<CustomerAccountItem[]>(this.customerAccountsUrl);
  }
  removeContract(contractNumber: string): Observable<void> {
    return this.http.post<void>(this.customerAccountsUrl, contractNumber);
  }
  updateInvoiceDeliveryMethod(
    invoiceDeliveryMethod: string,
    contractNumber: string,
    addressDetails: AddressDetails
  ) {
    const url = `${this.customerAccountsUrl}/${contractNumber}/${invoiceDeliveryMethod}`;
    const body = {
      invoiceDeliveryMethod: invoiceDeliveryMethod,
      contractNumber: contractNumber,
      addressDetails: AddressDetails,
    };
    return this.http.put<void>(url, addressDetails);
  }
}
